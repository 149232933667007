import { Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";

export interface IEscapePhoneProps {
  /** The value of the phone number to be displayed. */
  phoneNumber: string;
}

const formatPhoneNumberHyperlink = (phoneNumber: string): string =>
  `tel:+1${phoneNumber.replace(/-|[(]|[)]|[ ]/g, "")}`;

/* Displays the phone number value given with an appropriate hyperlink to trigger a phone call when executed. */
const EscapePhone: React.FC<IEscapePhoneProps> = ({ phoneNumber }) => {
  const [hyperlink, setHyperlink] = useState<string>(formatPhoneNumberHyperlink(phoneNumber));
  useEffect(() => setHyperlink(formatPhoneNumberHyperlink(phoneNumber)), [phoneNumber]);
  return (
    <a href={hyperlink}>
      <Typography variant="body1" color="secondary" fontWeight="bold" component="span">
        {phoneNumber}
      </Typography>
    </a>
  );
};

export default memo(EscapePhone);
