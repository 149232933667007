import { Stack, Typography } from "@mui/material";
import React from "react";

import EnvironmentConfiguration from "../constants/EnvironmentConfiguration";
import EscapePhone from "../V2/App/Components/EscapePhone";

const NeedHelpDialog: React.FC = () => {
  return (
    <Stack spacing={2}>
      <Typography id="welcomeStep_notYou" variant="h5">
        Need Help?
      </Typography>
      <Typography id="welcomeStep_pleaseContact" variant="body1">
        Please contact us at <EscapePhone phoneNumber={EnvironmentConfiguration.escapePhoneNumber} /> to update your
        information and schedule your consultation.
      </Typography>
    </Stack>
  );
};

export default NeedHelpDialog;
