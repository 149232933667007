import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { IControlledMutator, IOptions } from "sonobello.utilities.react";

import EnvironmentConfiguration from "../../../../constants/EnvironmentConfiguration";
import { isMobileView } from "../../../../utils/Constants";
import EscapePhone from "../../../App/Components/EscapePhone";
import { ICalendarService } from "../../../Calendar/Types/ICalendar";
import IBookableCenter from "../../../Types/IBookableCenter";

export interface ICenterSelectorProps extends IControlledMutator<IBookableCenter>, IOptions<IBookableCenter> {
  /** The currently selected service. */
  service: Pick<ICalendarService, "name">;
}

export interface ICenterSelectStepProps extends IControlledMutator<IBookableCenter>, IOptions<IBookableCenter> {
  /** The currently selected service to book. */
  service: Pick<ICalendarService, "name">;
}

export interface ICenterSelectStepConfig {
  /** The selector which allows the user to designate a new center for booking. */
  CenterSelector: React.FC<ICenterSelectorProps>;
}

/** Component which contains the content for selecting the consult center in which the booked appointment will occur. */
const CenterSelectStep: React.FC<ICenterSelectStepProps & ICenterSelectStepConfig> = ({
  service,
  value,
  CenterSelector,
  ...rest
}) => {
  const showMobile = isMobileView();
  return (
    <Stack spacing={showMobile ? undefined : 2} sx={{ width: "100%", height: "100%" }}>
      <Typography variant="h5" align="center">
        Please Select a Center:
      </Typography>
      <Stack spacing={2} sx={{ mb: 2, py: 1, overflow: "auto" }}>
        <CenterSelector {...rest} value={value} service={service} />
        <Box>
          <Typography variant="h6" align="center">
            Not Finding a Center Close Enough?
          </Typography>
          <Typography variant="body1" align="center">
            Call us at <EscapePhone phoneNumber={EnvironmentConfiguration.escapePhoneNumber} />.
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default CenterSelectStep;
