import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { LeafLogoSvg } from "sonobello.utilities.react.mui";

import EnvironmentConfiguration from "../../constants/EnvironmentConfiguration";
import EscapePhone from "../../V2/App/Components/EscapePhone";
import ViewWrapper from "./ViewWrapper";

/** The web page shown when the user has a valid session but the authorization token cannot be renewed. */
const NonCandidateView: React.FC = () => {
  const theme = useTheme();
  return (
    <ViewWrapper
      header=""
      icon={
        <LeafLogoSvg
          fill={theme.palette.primary.main}
          style={{ height: theme.spacing(10), width: theme.spacing(10) }}
        />
      }
      hideNeedHelpDialog
    >
      <Stack spacing={3} sx={{ width: "100%" }}>
        <Typography variant="body1">
          Thank you for completing our medical screening questions. Based on your selections, you are not considered a
          candidate for our procedures at this time.
        </Typography>
        <Typography variant="body1">
          If you believe you may have made an incorrect selection, please contact us at{" "}
          <EscapePhone phoneNumber={EnvironmentConfiguration.escapePhoneNumber} /> and one of our Patient Ambassadors
          can walk you through our medical screening questions.
        </Typography>
      </Stack>
    </ViewWrapper>
  );
};

export default NonCandidateView;
